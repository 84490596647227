/** @jsx jsx */
import { useState } from 'react';
import { jsx } from 'theme-ui';
import { graphql } from 'gatsby';
import ExploreLayout from '../../components/ExploreLayout';
import ExploreTwoColumnDisplay from '../../components/ExploreTwoColumnDisplay';
import {
  useThankYouParams,
  addThankYouParams,
  checkLocalStorageForURL,
} from '../../utils/paramsUtils';
import { getFeaturedEntries } from '../../utils/getFeaturedEntires';

export const resourcePageQuery = graphql`
  query (
    $slug: String!
    $language: String!
    $nodeLocale: String!
    $isHeaderV3Enabled: Boolean!
    $translationLocale: [String]
  ) {
    ...HeaderFooterSocialQuery
    ...ResourceQuery
    ...TranslationsFeaturedResourcesQuery
    ...ExploreSiteMetadataQuery
    ...LocalesQuery
  }
`;

const ResourcePageTemplate = ({ data, pageContext }) => {
  const {
    contentfulResource,
    translationsFeaturedResources,
    additionalLocalesFeaturedResources,
    headerV3,
    footerV3,
    allContentfulGlobalHeaderFooter,
    site,
  } = data;
  const { previewImage, featuredResources, personalization } =
    contentfulResource;

  const [isGated, setIsGated] = useState(true);
  const slug = contentfulResource?.slug;

  useThankYouParams(setIsGated);
  checkLocalStorageForURL(slug, setIsGated);

  const handleFormSubmit = () => {
    setIsGated(false);
    addThankYouParams();
    localStorage.setItem(slug, slug);
    window.scrollTo(0, 0);
  };

  if (!isGated && contentfulResource?.downloadAssetUrl)
    window.open(contentfulResource?.downloadAssetUrl);

  const featuredEntriesToRender = getFeaturedEntries({
    translationsFeaturedEntries: translationsFeaturedResources,
    additionalLocalesFeaturedEntries: additionalLocalesFeaturedResources,
    featuredEntries: featuredResources,
    slug,
    dateFieldName: 'sortingDate',
  });

  const twoColumnDisplayProps = {
    ...contentfulResource,
    exploreType: 'RESOURCE',
    featuredEntries: featuredEntriesToRender,
    resourceImage: previewImage,
    handleFormSubmit,
    isGated,
  };

  return (
    <ExploreLayout
      context={pageContext}
      slug={`/resources/${slug}`}
      metadata={contentfulResource?.metadata}
      site={site}
      headerV3={headerV3}
      footerV3={footerV3}
      globalSkeletonData={allContentfulGlobalHeaderFooter?.edges[0]?.node}
      personalization={personalization}
    >
      <ExploreTwoColumnDisplay {...twoColumnDisplayProps} />
    </ExploreLayout>
  );
};

export default ResourcePageTemplate;
